import { useState } from 'react';
import { sampleDataRadioBox } from './data';
import './style.scss';

export default function RadioBox({
  data = sampleDataRadioBox,
  getSelect,
  current,
}) {
  const [select, setSelect] = useState(current);
  return (
    <div className='radio-box'>
      {data.map(({ label, value }) => (
        <div
          className={`box ${select === value ? 'active' : ''}`}
          onClick={() => {
            setSelect(value);
            getSelect(value);
          }}
          key={value}
        >
          {label}
        </div>
      ))}
    </div>
  );
}
