/* eslint-disable indent */
export const linkTextWa = ({ pemesan,  selectDay, selectTime, selectedTicket, total }) => encodeURIComponent(
`Hallo Admin Museum Prabu Geusan Ulun,
Saya ingin berkunjung:
----------------------------------------
Atas Nama: *${pemesan}*
Hari Kunjugan: *${selectDay}*
Jam Kunjungan: *${selectTime}*
Jumlah tiket:
- ${selectedTicket[0]?.type} Rp. ${selectedTicket[0]?.price} x${selectedTicket[0]?.quantity}
${selectedTicket[1] ? `- ${selectedTicket[1]?.type} Rp. ${selectedTicket[1]?.price} x${selectedTicket[1]?.quantity}` : ''}
${selectedTicket[2] ? `- ${selectedTicket[2]?.type} Rp. ${selectedTicket[2]?.price} x${selectedTicket[2]?.quantity}` : ''}
Total: *${(total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}*
----------------------------------------
Terima kasih`);
