import React from 'react';

export default function PhoneSvg() {
  return (
    <svg
      fill='none'
      height='72'
      viewBox='0 0 73 72'
      width='73'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_164_1739)'>
        <path
          d='M56.9295 38.9947C56.578 38.6426 56.1605 38.3632 55.7009 38.1726C55.2413 37.982 54.7487 37.8839 54.2511 37.8839C53.7536 37.8839 53.2609 37.982 52.8013 38.1726C52.3417 38.3632 51.9242 38.6426 51.5727 38.9947L45.534 45.0334C42.7344 44.1999 37.5102 42.3057 34.1992 38.9947C30.8881 35.6836 28.9939 30.4594 28.1605 27.6598L34.1992 21.6211C34.5513 21.2696 34.8306 20.8521 35.0212 20.3925C35.2118 19.9329 35.3099 19.4403 35.3099 18.9427C35.3099 18.4452 35.2118 17.9525 35.0212 17.4929C34.8306 17.0333 34.5513 16.6159 34.1992 16.2643L19.0456 1.11076C18.6941 0.758646 18.2766 0.479298 17.817 0.288701C17.3574 0.0981045 16.8647 0 16.3672 0C15.8696 0 15.377 0.0981045 14.9174 0.288701C14.4578 0.479298 14.0403 0.758646 13.6888 1.11076L3.41467 11.3849C1.97508 12.8245 1.16436 14.802 1.19467 16.8212C1.2818 22.2159 2.71003 40.9533 17.4772 55.7204C32.2443 70.4876 50.9817 71.912 56.3802 72.003H56.4863C58.4865 72.003 60.377 71.215 61.809 69.783L72.0831 59.5088C72.4352 59.1573 72.7146 58.7398 72.9052 58.2802C73.0957 57.8207 73.1938 57.328 73.1938 56.8304C73.1938 56.3329 73.0957 55.8402 72.9052 55.3807C72.7146 54.9211 72.4352 54.5036 72.0831 54.1521L56.9295 38.9947ZM56.4484 64.4224C51.7205 64.3428 35.544 63.0737 22.834 50.3599C10.0822 37.6081 8.84722 21.3749 8.77146 16.7417L16.3672 9.14594L26.164 18.9427L21.2656 23.8411C20.8203 24.2861 20.4929 24.835 20.313 25.4382C20.1331 26.0414 20.1063 26.68 20.2351 27.2961C20.3261 27.7318 22.5498 38.0627 28.8386 44.3515C35.1273 50.6402 45.4583 52.864 45.8939 52.9549C46.5097 53.0874 47.1489 53.0627 47.7527 52.8833C48.3565 52.7039 48.9054 52.3755 49.3489 51.9283L54.2511 47.0299L64.0479 56.8267L56.4484 64.4224Z'
          fill='#0081AB'
        />
      </g>
      <defs>
        <clipPath id='clip0_164_1739'>
          <rect
            fill='white'
            height='72'
            transform='translate(0.5)'
            width='72'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
