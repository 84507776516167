/* eslint-disable max-len */
/* eslint-disable quotes */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const serverApi = createApi({
  reducerPath: 'serverApi',
  baseQuery: fetchBaseQuery({
    baseUrl:
      'https://2fijo688.apicdn.sanity.io/v2021-10-21/data/query/production',
  }),
  tagTypes: ['kegiatan', 'banner', 'katalog', 'kategori', 'produk'],
  endpoints: (builder) => ({
    getKegiatan: builder.query({
      query: () => ({
        url: '?query=*%5B_type%20%3D%3D%20%22kegiatan%22%5D',
      }),
      providesTags: ['kegiatan'],
    }),
    getBanner: builder.query({
      query: () => ({
        url: '?query=*%5B_type%20%3D%3D%20%22banner%22%5D',
      }),
      providesTags: ['banner'],
    }),
    getKatalog: builder.query({
      query: () => ({
        url: `?query=${encodeURI("*[_type == 'katalog']")}`,
      }),
      providesTags: ['katalog'],
    }),
    getListKatagori: builder.query({
      query: () => ({
        url: `?query=*%5B_type%20%3D%3D%20'kategori'%5D`,
      }),
    }),
    getListProduk: builder.query({
      query: (kategoriSlug) => ({
        url: `?query=*%5B_type%20%3D%3D%20'produk'%20%26%26%20kategoriSlug%20%3D%3D%20'${kategoriSlug}'%5D`,
      }),
    }),
  }),
});

export const {
  useGetKegiatanQuery,
  useGetBannerQuery,
  useGetKatalogQuery,
  useGetListKatagoriQuery,
  useGetListProdukQuery,
} = serverApi;
