import { useEffect, useState } from 'react';
import About from '../../components/about';
import Activity from '../../components/activity';
import Catalog from '../../components/catalog';
import Footer from '../../components/footer';
import GetInTouch from '../../components/getInTouch';
import Header from '../../components/header';
import History from '../../components/history';
import ImageSlider from '../../components/imageSlider';
import MoveToTop from '../../components/moveToTop';
import PopupHome from '../../components/popupHome';
import './style.scss';

export default function Home() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className='home-page'>
        <ImageSlider />
        <About setShowPopup={setShowPopup} />
        <History />
        <Catalog />
        <Activity />
        <GetInTouch />
        <PopupHome setShow={setShowPopup} show={showPopup} />
      </div>
      <Footer />
      <MoveToTop />
    </>
  );
}
