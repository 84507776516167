import RadioBox from '../../components/ticketing/RadioBox';
import SelectTicket from '../../components/ticketing/SelectTicket';

export default function Page2({
  setSelectDay,
  setSelectTime,
  setSelectTicket,
  setPemesan,
  pemesan,
  currentTime,
  currentDay,
}) {
  return (
    <>
      <div className='title-column'>
        <div className='container'>
          <div className='title'>Layanan Museum</div>
        </div>
        <div className='informasi'>
          <p>
            Untuk kunjungan sekolah dan rombongan, hubungi kami untuk memesan
            slot waktu khusus. Informasi lebih lanjut hubungi nomor :{' '}
          </p>
          <p>Chat WA : 0878 1306 6669</p>
          <p>Telepon : 0261 201 714</p>
        </div>
      </div>
      <div className='separator' />
      <div className='title-column'>
        <div className='container'>
          <div className='title'>Pemesanan Tiket</div>
        </div>
        <div className='kunjungan'>
          <h3>01 Pilih Hari Kunjungan </h3>
          <RadioBox getSelect={setSelectDay} current={currentDay} />
          <div className='separator' />
          <h3>02 Pilih Waktu Kunjungan </h3>
          <p>Perkiraan waktu kunjugan Anda</p>
          <RadioBox
            getSelect={setSelectTime}
            data={[
              { label: '09:00', value: '09:00' },
              { label: '10:00', value: '10:00' },
              { label: '11:00', value: '11:00' },
              { label: '12:00', value: '12:00' },
            ]}
            current={currentTime}
          />
          <div className='separator' />
          <h3>03 Jumlah Tiket</h3>
          <SelectTicket getTicket={setSelectTicket} />
          <div className='separator' />
          <h3>04 Nama Pemesan</h3>
          <p>Tuliskan nama lengkap anda</p>
          <input
            type='text'
            value={pemesan}
            onChange={(e) => setPemesan(e.target.value)}
            placeholder='Nama lengkap'
          />
          <div className='separator' />
        </div>
      </div>
    </>
  );
}
