import { useNavigate } from 'react-router-dom';

export default function HomeSvg() {
  const navigate = useNavigate();

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => navigate('/')}
      cursor='pointer'
    >
      <path
        d='M19.7942 7.52L12.8257 2.58475C12.331 2.2344 11.669 2.2344 11.1743 2.58475L4.20584 7.52C3.44952 8.05564 3 8.92485 3 9.85162V19.1429C3 20.7208 4.27919 22 5.85714 22H8C8.78898 22 9.42857 21.3604 9.42857 20.5714V18.4286C9.42857 17.6396 10.0682 17 10.8571 17H13.1429C13.9318 17 14.5714 17.6396 14.5714 18.4286V20.5714C14.5714 21.3604 15.211 22 16 22H18.1429C19.7208 22 21 20.7208 21 19.1429V9.85162C21 8.92485 20.5505 8.05564 19.7942 7.52Z'
        fill='black'
      />
    </svg>
  );
}
