export const dataBoxes = [
  [
    {
      value: `/aset 10 gambar artefak/Keris Ki Dukun.jpg`,
      imgSmall: '/aset 10 gambar artefak/Keris Ki Dukun - small.jpg',
      type: 'image',
      identity: 'Keris Ki Dukun',
    },
    {
      value: `/aset 10 gambar artefak/keris wayang (2).png`,
      imgSmall: '/aset 10 gambar artefak/keris wayang (2) - small.jpg',
      type: 'image',
      identity: 'Kujang Wayang',
    },
    {
      value: `/aset 10 gambar artefak/Pedang Ki Mastak.jpg`,
      imgSmall: '/aset 10 gambar artefak/Pedang Ki Mastak - small.jpg',
      type: 'image',
      identity: 'Pedang Ki Mastak',
    },
  ],
  [
    {
      value: `/aset 10 gambar artefak/Pedang Badik Curug Aul.png`,
      imgSmall: '/aset 10 gambar artefak/Pedang Badik Curug Aul - small.jpg',
      type: 'image',
      identity: 'Pedang Badik Curug Aul',
    },
    {
      value: `Keris Ki Dukun`,
      type: 'text',
      identity: 'Keris Ki Dukun',
    },
    {
      value: `Kujang Wayang`,
      type: 'text',
      identity: 'Kujang Wayang',
    },
  ],
  [
    {
      value: `Pedang Ki Mastak`,
      type: 'text',
      identity: 'Pedang Ki Mastak',
    },
    {
      value: `Pedang Badik Curug Aul`,
      type: 'text',
      identity: 'Pedang Badik Curug Aul',
    },
    {
      value: ``,
      type: 'text',
      identity: '',
    },
  ],
];
