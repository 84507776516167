export default function CloseSvg({ onClick }) {
  return (
    <svg
      fill='none'
      height='34'
      viewBox='0 0 34 34'
      width='34'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        clipRule='evenodd'
        d='M33.6663 17C33.6663 26.2048 26.2044 33.6667 16.9997 33.6667C7.79493 33.6667 0.333008 26.2048 0.333008 17C0.333008 7.79526 7.79493 0.333344 16.9997 0.333344C26.2044 0.333344 33.6663 7.79526 33.6663 17ZM11.4694 11.4695C11.7623 11.1766 12.2371 11.1766 12.53 11.4695L16.9997 15.9391L21.4693 11.4695C21.7622 11.1766 22.2371 11.1766 22.53 11.4695C22.8229 11.7624 22.8229 12.2372 22.53 12.5301L18.0603 16.9998L22.53 21.4695C22.8229 21.7624 22.8229 22.2372 22.53 22.5301C22.2371 22.823 21.7623 22.823 21.4694 22.5301L16.9997 18.0604L12.53 22.5301C12.2371 22.823 11.7622 22.823 11.4693 22.5301C11.1764 22.2372 11.1764 21.7624 11.4693 21.4695L15.939 16.9998L11.4694 12.5301C11.1765 12.2372 11.1765 11.7624 11.4694 11.4695Z'
        fill='black'
        fillRule='evenodd'
      />
    </svg>
  );
}
