import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import History from './pages/history';
import Catalog from './pages/catalog';
import Ticketing from './pages/ticketing';
import Cendramata from './pages/cendramata';
import Games from './pages/games';

function App() {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  });
  return (
    <Routes>
      <Route path='/' element={<Home />} exact />
      <Route path='/sejarah' element={<History />} exact />
      <Route path='/koleksi' element={<Catalog />} exact />
      <Route path='/reservasi' element={<Ticketing />} exact />
      <Route path='/games' element={<Games />} exact />
      <Route path='/cenderamata' element={<Cendramata />} exact />
    </Routes>
  );
}

export default App;
