import ContentHistory from '../../components/contentHistory';
import Section from '../../components/section';
import peristiwaCadasImg from '../../assets/foto2 pendukung/peristiwa cadas pangeran.jpg';
import gamalanImg from '../../assets/foto2 pendukung/gamelan sari oneng.jpg';
import keretaKencanaImg from '../../assets/foto2 pendukung/kereta kencana naga paksi.jpg';
import mahkotaImg from '../../assets/foto2 pendukung/mahkota binokasih dan siger.jpg';
import { useEffect } from 'react';
import KerisHistory from '../../components/KerisHistory';
import './index.scss';
import Footer from '../../components/footer';
import MoveToTop from '../../components/moveToTop';
import Header from '../../components/header';

export default function History() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <div className='pages_history'>
        <Section
          title='Sejarah Museum'
          subTitle={[
            'Mari simak mengenai sejarah museum Prabu Geusan Ulun.',
            'Perubahan nama dari Selayang Pandang Museum menjadi Prabu Geusan Ulun.',
          ]}
        />
        <div className='container'>
          <ContentHistory
            desc='Pada masa pemerintahan Ratu Pucuk Umun Ratu Sumedang Larang ke8, ibu dari Pangeran Angkawijaya (dikemudian hari dinobatkan sebagai Raja Sumedang Larang dan dikenal dengan Prabu Geusan Ulun), pada tanggal 22 April 1578 atau bulan Syawal bertepatan dengan Hari Raya Idul Fitri, di Keraton Kutamaya Sumedang Larang menerima empat Pembesar Pajajaran yang dipimpin oleh Sang Hyang Hawu atau Jaya Perkosa, Batara Dipati Wiradidjaja (Nanganan), Sang Hyang Kondanghapa, dan Batara Pancar Buana Terong Peot membawa pusaka Pajajaran beserta Alas Kaprabon untuk diserahkan kepada penguasa Sumedang Larang yang selanjutnya akan menjadi penerus Kerajaan Pajajaran.'
            imgCaptions={[
              'Lukisan Peristiwa Penyerahan Pusaka Pajajaran',
              'Dilukis pada tahun 1978 oleh Titi Salmah.',
              'No.Reg. GU. 01937 No.Inv. 09. 55',
            ]}
          />
          <ContentHistory
            desc='Lukisan ini mengkisahkan tentang Peristiwa Cadas Pangeran dimana Pangeran Soerianagara Koesoemaadinata IX bertemu dengan Gubernur Jendral Daendels dalam pembuatan jalan raya pos, yang terjadi pada tanggal 26 November 1811 hingga tanggal 12 Maret 1812.'
            title=''
            imgCaptions={[
              'Lukisan tentang Peristiwa Cadas Pangeran',
              'Dilukis pada Desember tahun 1977, oleh Rachmansjah S. Djajasoebrata.',
              'No.Reg. GU. 01936 No.Inv. 09.54',
            ]}
            imgSrc={peristiwaCadasImg}
            reverse
          />
          <ContentHistory
            desc='Berdasarkan naskah kuno Carita Parahiyangan Mahkota Binokasih Sang Hyang Pake dibuat oleh Sang Hyang Bunisora Soeriadipati (Batara Guru di Jampang) untuk digunakan sebagai penobatan raja Galuh yang bernama Niskala Wastu Kencana. Sang Hyang Bunisora bertapa bakti terlebih dahulu kepada Dewata sebelum pembuatan mahkota sehingga bentuk mahkota tersebut mengambil contoh dari mahkota Batara Indra. Penobatan Niskala Wastukancana sebagai Raja Galuh/Kawali, terjadi pada tahun 1371, sekaligus peresmiannya sebagai penerus dinasti Lingga Buana'
            title=''
            imgCaptions={[
              'Makuta Binokasih',
              'No.Reg. GU.00001X No.Inv. 06.1638',
            ]}
            imgSrc={mahkotaImg}
          />
        </div>
        <KerisHistory />
        <div className='container'>
          <ContentHistory
            desc='Kereta Kencana Naga Paksi dibuat pada masa Pangeran Aria Suria Kusumah Adinata (Pangeran Sugih) Bupati Sumedang tahun 1836 – 1882. Pangeran Sugih merekayasa bentuk kereta kencana ini untuk dijadikan kereta khusus yang digunakan untuk berkendara menuju tempat upacara disekitar alun-alun. Kereta Kencana Naga Paksi mempunyai tiga bentuk simbolik hewan yang memiliki arti : Gajah : Melambangkan ilmu pengetahuan atau kekuasaan. Naga : Melambangkan sumber kekuatan fisik dan perkataan yang bertuah. Garuda: Melambangkan persamaan dan kesetiaan secara timbal-balik. Simbol ini adalah untuk melambangkan pemerintahan bupati masa itu.'
            title=''
            imgCaptions={[
              'Kereta Kencana Naga Paksi',
              'No. Reg. GU 00922 No.Inv.05.2609 ',
            ]}
            imgSrc={keretaKencanaImg}
            reverse
          />
        </div>
      </div>
      <Footer />
      <MoveToTop />
    </>
  );
}
