import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { AiFillCloseCircle, AiOutlineMenu } from 'react-icons/ai';

import ListMenu from './ListMenu';
import { useHeaderShadow } from '../../hooks/useHeaderShadow';

import './style.scss';

export default function Header() {
  const tabPort = useMediaQuery({ query: '(max-width: 37.5em)' });
  const [isMenuActive, setIsMenuActive] = useState(false);
  const showShadow = useHeaderShadow();

  return (
    <>
      <div className={`header ${showShadow ? 'shadow' : ''}`}>
        <Link to='/'>
          <img src='/Logo Logo/logo museum(1).png' alt='logo museum' />
        </Link>
        <ul>
          {tabPort ? (
            <AiOutlineMenu
              className='menu'
              onClick={() => setIsMenuActive((prev) => !prev)}
            />
          ) : (
            <ListMenu />
          )}
        </ul>
      </div>
      {tabPort && isMenuActive && (
        <ul className='menu-mobile'>
          <AiFillCloseCircle
            className='menu close'
            onClick={() => setIsMenuActive(false)}
          />
          <ListMenu />
        </ul>
      )}
    </>
  );
}
