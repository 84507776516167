import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import HomeSvg from '../../assets/svg/HomeSvg';
import GameMobile from '../../components/GameMobile';
import './style.scss';

export default function Games() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isMobile ? (
        <GameMobile />
      ) : (
        <div className='games'>
          <HomeSvg />
          <div className='images'>
            <img src='/foto2 pendukung/game - mock up (2).png' alt='' />
          </div>
          <div className='content'>
            <div className='title'>
              The New Museum Prabu Geusan Ulun Berbasis Museum Inklusi{' '}
            </div>
            <div className='middle'>
              <h5>Maaf,</h5>
              <p>untuk sekarang kamu hanya bisa menikmati</p>
              <h2>Jelajahi Koleksi Karya di Museum Melalui Mini Game </h2>
              <span>lewat ponselmu</span>
              <img src='/foto2 pendukung/QR AR Pusaka MPGU.png' alt='' />
              <figcaption>Scan Barcode</figcaption>
            </div>
            <div className='bottom'>
              <img src='/Logo Logo/logo museum(1).png' alt='' />
              <img src='/Logo Logo/logo telkom.png' alt='' />
              <img src='/Logo Logo/logo innovilage.png' alt='' />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
