export default function ArrowSvg() {
  return (
    <svg
      fill='none'
      height='24'
      viewBox='0 0 24 24'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 5L15.57 11.6237C15.7976 11.8229 15.7976 12.1771 15.57 12.3763L8 19'
        stroke='#0081AB'
        strokeLinecap='round'
        strokeWidth='1.5'
      />
    </svg>
  );
}
