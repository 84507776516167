import { useRef } from 'react';
import CloseSvg from '../../assets/svg/CloseSvg';
import useClickOutside from '../../hooks/useClickOutside';
import ArrowLeft from '../../assets/svg/ArrowLeft';
import ArrowRight from '../../assets/svg/ArrowRight';
import { useMediaQuery } from 'react-responsive';
import './style.scss';

export default function PopupCatalog({
  setShow,
  show,
  title = 'Pedang Tjikoreh',
  number = 41,
  noInventaris = '041/A/i/MPGU/f/V/2018',
  desc = 'Pedang berwarangka. dua sisi bermotif geometris dan sulur. pada salah satu sisi bilah bagian dasar, tertera deskripsi "TJIKEROEH 1901"tampah jejak pemakaian pada sisi tajam bilah pedang. gagang pedang terbuat dari gading dihias kuningan. pada ujung pangkal gagang, terdapat bulatan seperti knob dengan ukiran penuh. dekat dasar gagang terdapat pelindung tangan berupa ring kuningan yang berbentuk elips dan kerang. warangka pedang terbuat dari kayu dilapisi logam kuningan ang sedikit aus.',
  history = 'Pedang Tjikeroeh, bilah Tjikeroeh sangat khas dan beraneka ragam karena dihias motif seperti tanaman yang disebut simbol pajimatan Wawanen. hulunya juga khas, bentuk bulat lonjong, ditutupdengan lgam bahan kuningan, dihiasi dengan tiga simbol binatang "kica-kica". Hand Guard (pelindung tangan) bentuk suku "mencek"dan bentuk kulit kerang, terbuat dari kuningan. pada zaman dahulu, senjata Tjikeroeh biasad ipakai oleh kaum Aom/bangsawan Sumedang',
  imgSrc = '/logo-museum.png',
  setSelectShow,
  length,
}) {
  const ref = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  useClickOutside(ref, () => setShow(false));

  if (!show) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <div className='popup-catalog mobile'>
          <div className='container' ref={ref}>
            <CloseSvg onClick={() => setShow(false)} />
            <div className='scroll'>
              <div className='content'>
                <div className='title'>{title}</div>
                <div className='inventaris'>
                  <span>no inventaris</span>
                  <span>{noInventaris}</span>
                </div>
                <div className='image'>
                  <ArrowRight
                    onClick={() => {
                      setSelectShow((prev) =>
                        prev < length - 1 ? (prev += 1) : 0
                      );
                    }}
                  />
                  <ArrowLeft
                    onClick={() => {
                      setSelectShow((prev) =>
                        prev > 1 ? (prev -= 1) : length - 1
                      );
                    }}
                  />
                  <div className='number'>{number}</div>
                  <div className='img-container'>
                    <img src={imgSrc} alt={title} />
                  </div>
                </div>
                <div className='separator' />
                <div className='sub-title'>Deskripsi</div>
                <div className='text'>{desc}</div>
                {history && (
                  <>
                    <div className='separator' />
                    <div className='sub-title'>History</div>
                    <div className='text'>{history}</div>
                  </>
                )}
                <figcaption>
                  <p>Scan barcode yang telah disediakan Museum</p>
                  <p>untuk melihat katalog dengan pengalaman yang berbeda</p>
                </figcaption>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='popup-catalog'>
          <div className='container' ref={ref}>
            <CloseSvg onClick={() => setShow(false)} />
            <ArrowRight
              onClick={() => {
                setSelectShow((prev) => (prev < length - 1 ? (prev += 1) : 0));
              }}
            />
            <ArrowLeft
              onClick={() => {
                setSelectShow((prev) => (prev > 1 ? (prev -= 1) : length - 1));
              }}
            />
            <div className='scroll'>
              <div className='content'>
                <div className='title'>{title}</div>
                <div className='number'>{number}</div>
                <div className='inventaris'>
                  <span>no inventaris</span>
                  <span>{noInventaris}</span>
                </div>
                <div className='separator' />
                <div className='sub-title'>Deskripsi</div>
                <div className='text'>{desc}</div>
                {history && (
                  <>
                    <div className='separator' />
                    <div className='sub-title'>History</div>
                    <div className='text pb'>{history}</div>
                  </>
                )}
              </div>
              <div className='image'>
                <div className='img-container'>
                  <img src={imgSrc} alt={title} />
                </div>
                <figcaption>
                  <p>Scan barcode yang telah disediakan Museum</p>
                  <p>untuk melihat katalog dengan pengalaman yang berbeda</p>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
