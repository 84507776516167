import { Link } from 'react-router-dom';
import BookTicketSvg from '../../assets/svg/BookTicketSvg';
import MiniGamesSvg from '../../assets/svg/MiniGamesSvg';
import MuseumProfileSvg from '../../assets/svg/MuseumProfileSvg';
import Section from '../section';
import './style.scss';

export default function About({ setShowPopup }) {
  return (
    <div className='about' id='profile'>
      <Section
        title='Tentang Museum'
        subTitle={[
          'Museum Prabu Geusan Ulun merupakan salah satu museum yang berada di Sumedang, Jawa Barat.',
          'Mari lihat, profile museum ini, bagaimana cara memesan tiket, dan visi-misinya seperti apa! ',
        ]}
      />
      <div className='row-box'>
        <Link to='/games' className='box'>
          <MiniGamesSvg />
          <h5>Mini games</h5>
        </Link>
        <div className='box active' onClick={() => setShowPopup(true)}>
          <MuseumProfileSvg />
          <h5>Profil Museum</h5>
        </div>
        <Link to='/reservasi' className='box'>
          <BookTicketSvg />
          <h5>Pemesanan Tiket</h5>
        </Link>
      </div>
    </div>
  );
}
