export default function ArrowLeft2({ onClick }) {
  return (
    <svg
      width='58'
      height='58'
      viewBox='0 0 58 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g filter='url(#filter0_d_417_2502)'>
        <circle cx='29' cy='25' r='25' fill='#FFFBFB' />
      </g>
      <path
        d='M33 18L25.43 24.6237C25.2024 24.8229 25.2024 25.1771 25.43 25.3763L33 32'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <defs>
        <filter
          id='filter0_d_417_2502'
          x='0'
          y='0'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_417_2502'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_417_2502'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
