import { useEffect, useState } from 'react';

export const useHeaderShadow = () => {
  const [showShadow, setShowShadow] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      const screenY = window.scrollY;
      // Header bar
      if (screenY > 10) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return showShadow;
};
