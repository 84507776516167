import { useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CloseSvg from '../../assets/svg/CloseSvg';
import useClickOutside from '../../hooks/useClickOutside';
import { urlFor } from '../../lib/client';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './style.scss';

export default function PopupItem({ setShow, show, data }) {
  const ref = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  useClickOutside(ref, () => setShow(false));
  if (!show || !data) {
    return null;
  }
  const {
    title,
    subTitle,
    dimension,
    price,
    description,
    imageCover,
    gallery,
    material,
  } = data;
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [indexStartPhoto, setIndexStartPhoto] = useState(0);

  const allImages = [imageCover, ...gallery];

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75) {
      // do your stuff here for left swipe
      setSelectedImageIndex((curr) =>
        curr === 0 ? allImages.length - 1 : curr - 1
      );
    }

    if (touchStart - touchEnd < -75) {
      // do your stuff here for right swipe
      setSelectedImageIndex((curr) =>
        curr === allImages.length - 1 ? 0 : curr + 1
      );
    }
  }

  return (
    <>
      {isMobile ? (
        <div className='popup-item mobile'>
          <div className='container' ref={ref}>
            <CloseSvg onClick={() => setShow(false)} />
            <div className='scroll'>
              <div className='content'>
                <div className='title'>{title}</div>
                <div className='short-desc'>{subTitle}</div>
                {price && <div className='price'>Rp. {price}</div>}
                <div className='separator' />
                <div className='image'>
                  <div className='img-container'>
                    <img
                      src={urlFor(allImages[selectedImageIndex])}
                      alt='image'
                      onTouchStart={(touchStartEvent) =>
                        handleTouchStart(touchStartEvent)
                      }
                      onTouchMove={(touchMoveEvent) =>
                        handleTouchMove(touchMoveEvent)
                      }
                      onTouchEnd={() => handleTouchEnd()}
                    />
                  </div>
                  <div className='image-thumb'>
                    {allImages?.map((image, index) => {
                      if (index === selectedImageIndex) {
                        return <div className='dot active' key={index} />;
                      }
                      return (
                        <div
                          className='dot'
                          key={index}
                          onClick={() => setSelectedImageIndex(index)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className='sub-title'>Deskripsi</div>
                <div className='text'>{description}</div>
                {dimension?.length > 0 && (
                  <>
                    <div className='sub-title'>Dimensi</div>
                    <div className='dimensi'>
                      {dimension?.map((el, index) => (
                        <div key={index}>{el}</div>
                      ))}
                    </div>
                  </>
                )}
                <div className='sub-title'>Material</div>
                <div className='dimensi'>
                  {material?.map((mat, index) => (
                    <div key={index}>{mat}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='popup-item'>
          <div className='container' ref={ref}>
            <CloseSvg onClick={() => setShow(false)} />
            <div className='scroll'>
              <div className='content'>
                <div className='title'>{title}</div>
                <div className='short-desc'>{subTitle}</div>
                {price && <div className='price'>Rp. {price}</div>}
                <div className='separator' />
                <div className='sub-title'>Deskripsi</div>
                <div className='text'>{description}</div>
                {dimension?.length > 0 && (
                  <>
                    <div className='sub-title'>Dimensi</div>
                    <div className='dimensi'>
                      {dimension?.map((el, index) => (
                        <div key={index}>{el}</div>
                      ))}
                    </div>
                  </>
                )}
                <div className='sub-title'>Material</div>
                <div className='dimensi'>
                  {material?.map((mat, index) => (
                    <div key={index}>{mat}</div>
                  ))}
                </div>
              </div>
              <div className='image'>
                <div className='img-container'>
                  <img
                    src={urlFor(allImages[selectedImageIndex])}
                    alt='image'
                  />
                </div>
                <div className='image-thumb'>
                  {allImages?.map((image, index) => {
                    if (
                      index < indexStartPhoto ||
                      index > indexStartPhoto + 3
                    ) {
                      return null;
                    }
                    return (
                      <img
                        key={index}
                        src={urlFor(image).url()}
                        onClick={() => setSelectedImageIndex(index)}
                        alt='image'
                      />
                    );
                  })}
                  {indexStartPhoto > 0 && (
                    <div className='icon left'>
                      <FaArrowLeft
                        onClick={() => setIndexStartPhoto((prev) => prev - 1)}
                      />
                    </div>
                  )}
                  {allImages.length - 4 - indexStartPhoto > 0 && (
                    <div className='icon right '>
                      <FaArrowRight
                        onClick={() => setIndexStartPhoto((prev) => prev + 1)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
