export default function MiniGamesSvg() {
  return (
    <svg
      fill='none'
      height='48'
      viewBox='0 0 48 48'
      width='48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_164_1552)'>
        <path
          d='M42.5886 12.5253H37.519V7.45567C37.519 6.55931 37.1629 5.69966 36.5291 5.06583C35.8953 4.43201 35.0356 4.07593 34.1393 4.07593H13.8608C12.9644 4.07593 12.1048 4.43201 11.4709 5.06583C10.8371 5.69966 10.481 6.55931 10.481 7.45567V12.5253H5.41142C4.06687 12.5253 2.77739 13.0594 1.82665 14.0102C0.875916 14.9609 0.341797 16.2504 0.341797 17.5949V41.2531C0.341797 42.1495 0.697876 43.0092 1.3317 43.643C1.96553 44.2768 2.82518 44.6329 3.72154 44.6329H44.2785C45.1749 44.6329 46.0345 44.2768 46.6683 43.643C47.3022 43.0092 47.6583 42.1495 47.6583 41.2531V17.5949C47.6583 16.2504 47.1241 14.9609 46.1734 14.0102C45.2227 13.0594 43.9332 12.5253 42.5886 12.5253ZM13.8608 7.45567H34.1393V12.5253H13.8608V7.45567ZM44.2785 41.2531H3.72154V26.0443H17.2405V34.4936H30.7595V26.0443H44.2785V41.2531ZM20.6203 26.0443H27.3798V31.1139H20.6203V26.0443ZM3.72154 22.6645V17.5949C3.72154 17.1467 3.89958 16.7169 4.2165 16.4C4.53341 16.0831 4.96323 15.905 5.41142 15.905H42.5886C43.0368 15.905 43.4666 16.0831 43.7836 16.4C44.1005 16.7169 44.2785 17.1467 44.2785 17.5949V22.6645H3.72154Z'
          fill='#0081AB'
        />
      </g>
      <defs>
        <clipPath id='clip0_164_1552'>
          <rect
            fill='white'
            height='40.7089'
            transform='translate(0 4)'
            width='48'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
