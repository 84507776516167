import { useRef } from 'react';
import CloseSvg from '../../assets/svg/CloseSvg';
import museumImg from '../../assets/foto2 pendukung/museum tampak depan.jpg';
import useClickOutside from '../../hooks/useClickOutside';
import './style.scss';

export default function PopupHome({ setShow, show }) {
  const ref = useRef(null);
  useClickOutside(ref, () => setShow(false));

  if (!show) {
    return null;
  }

  return (
    <div className='popup-home'>
      <div className='container' ref={ref}>
        <CloseSvg onClick={() => setShow(false)} />
        <div className='scroll'>
          <div className='title'>Profil Museum</div>
          <img alt='museum' src={museumImg} />
          <div className='content'>
            <div className='desc'>
              <p>
                Museum Prabu Geusan Ulun berdiri pada 11 November 1973. Semula
                museum ini hanya untuk kalangan keluarga saja. Kemudian pada
                tanggal 7 – 13 Maret tahun 1974 saat seminar sejarah tentang
                penetapan hari jadi Sumedang, para ahli sejarah Jawa Barat yang
                ada pada saat itu mengusulkan perubahan nama museum menjadi
                Museum Prabu Geusan Ulun.
              </p>
              <p>
                Museum Prabu Geusan Ulun resmi terbuka untuk umum tanggal 24
                Januari 1985. Museum pada saat ini menempati 6 gedung terdiri
                dari Gedung Srimanganti, Bumi Kaler, Gendeng, Gedung Pusaka,
                Gedung Gamelan dan Gedung Kareta.
              </p>
              <p>
                Sampai dengan tahun 2010 kepala pengelola museum dijabatrangkap
                oleh Ketua Yayasan Pangeran Sumedang. Sejak tahun 2010 museum
                menjadi bidang tersendiri dibawah naungan Yayasan Pangeran
                Sumedang. Kemudian dikarenakan satu dan hal lainnya Museum Prabu
                Geusan Ulun saat ini berada dibawah pengelolaan Yayasan Nazhir
                Wakaf Pangeran Sumedang.
              </p>
              <p>
                Museum Prabu Geusan Ulun berdiri diatas tanah wakaf (tanah
                milik) seluas ± 2,3 Ha dengan luas komplek bangunan museum
                seluas ± 1,8 Ha. Adapun bangunan yang digunakan sebagai museum
                terdiri dari 2 bangunan cagar budaya, 2 bangunan lama bukan
                cagar budaya, dan 2 bangunan baru.
              </p>
            </div>
            <div className='visi'>
              <h5>Visi</h5>
              <p>
                Terpeliharanya wakaf peninggalan dan terwujudnya amanah para
                leluhur Sumedang di bidang sosial budaya dengan dilandasi
                semangat kekeluargaan dalam tali kawargian serta ditopang oleh
                sumber daya manusia yang beriman, bertaqwa dan berkualitas untuk
                menghadapi segala tantangan di era globalisasi
              </p>
            </div>
            <div className='misi'>
              <h5>Misi</h5>
              <ul>
                <li>
                  1. Meningkatkan upaya penertiban dan pemeliharaan Aset/wakaf
                  Pangeran Aria Soeria Atmadja
                </li>
                <li>
                  2. Meningkatkan, melestarikan seni, adat dan budaya warisan
                  leluhur Sumedang
                </li>
                <li>3. Menunjang peningkatan pariwisata daerah</li>
                <li>4. Mempererat tali silaturahmi antar wargi</li>
                <li>5. Turut serta meningkatkan bidang pendidikan</li>
                <li>6. Meningkatkan kinerja organisasi</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
