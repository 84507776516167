import { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useGetKatalogQuery } from '../../app/serverApi';
import Card from '../../components/card';
import Footer from '../../components/footer';
import GetInTouch from '../../components/getInTouch';
import Header from '../../components/header';
import MoveToTop from '../../components/moveToTop';
import PopupCatalog from '../../components/popupCatalog';
import Section from '../../components/section';
import { dataCatalog } from '../../data/dataCatalog';
import { urlFor } from '../../lib/client';
import './style.scss';

export default function Catalog() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectShow, setSelectShow] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 37.5em)' });
  const { data, isLoading } = useGetKatalogQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return null;
  }

  const handleShowPopup = (index) => {
    const showData = data?.result[index];
    if (!showData) {
      return null;
    }
    return (
      <PopupCatalog
        setShow={setShowPopup}
        setSelectShow={setSelectShow}
        show={showPopup}
        desc={showData.desc}
        history={showData.history}
        imgSrc={urlFor(showData.image)}
        noInventaris={showData.no_inventaris}
        number={showData.number}
        title={showData.title}
        index={selectShow}
        length={dataCatalog.length}
      />
    );
  };

  return (
    <>
      <Header />
      <div className='catalog-page'>
        <Section
          title='Koleksi Museum'
          subTitle={[
            'Mari melihat-lihat koleksi Museum Prabu Geusan Ulun,',
            'yang meliputi 6 gedung  salah satunya adalah Gedung Pusaka.',
          ]}
        />
        <div className='grid'>
          {data?.result?.map((e, i) => (
            <Fragment key={i}>
              <Card
                dataCatalog={data?.result}
                altImg={e.title}
                image={urlFor(e.image)}
                noInventaris={e.no_inventaris}
                index={i}
                number={e.number}
                title={e.title}
                desc={e.desc}
                history={e.history}
                setSelectShow={setSelectShow}
                setShowPopup={setShowPopup}
              />
            </Fragment>
          ))}
        </div>
        {handleShowPopup(selectShow)}
        <GetInTouch />
      </div>
      <Footer />
      <MoveToTop />
    </>
  );
}
