export default function ArrowRight({ onClick }) {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9997 36.6666C29.2044 36.6666 36.6663 29.2047 36.6663 20C36.6663 10.7952 29.2044 3.33331 19.9997 3.33331C10.7949 3.33331 3.33301 10.7952 3.33301 20C3.33301 29.2047 10.7949 36.6666 19.9997 36.6666ZM17.4472 12.3572C16.9081 11.926 16.1215 12.0134 15.6903 12.5524C15.259 13.0915 15.3464 13.8781 15.8855 14.3094L22.9987 20L15.8855 25.6906C15.3464 26.1218 15.259 26.9084 15.6903 27.4475C16.1215 27.9866 16.9081 28.074 17.4472 27.6427L24.9671 21.6268C26.0096 20.7928 26.0096 19.2072 24.9671 18.3732L17.4472 12.3572Z'
        fill='black'
      />
    </svg>
  );
}
