import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';
import museumImg from '../../assets/foto2 pendukung/museum tampak depan.jpg';
import mahkotaImg from '../../assets/foto2 pendukung/mahkota binokasih dan siger.jpg';
import peristiwaImg from '../../assets/foto2 pendukung/peristiwa cadas pangeran.jpg';
import { useGetBannerQuery } from '../../app/serverApi';
import './style.scss';
import { urlFor } from '../../lib/client';

export default function ImageSlider() {
  const tabPort = useMediaQuery({ query: '(max-width: 37.5em)' });
  const { data, isLoading } = useGetBannerQuery();

  if (isLoading) {
    return null;
  }
  return (
    <>
      {tabPort ? (
        <div className='image-slider'>
          <div className='container'>
            <img
              src={urlFor(data?.result[0].image) || mahkotaImg}
              alt='mahkota'
            />
          </div>
          <div className='container'>
            <img src={museumImg} alt='museum' />
          </div>
          <div className='container'>
            <img src={peristiwaImg} alt='peristiwa' />
          </div>
        </div>
      ) : (
        <div id='home'>
          <Carousel
            autoPlay
            interval={2000}
            showStatus={false}
            centerMode
            showThumbs={false}
            infiniteLoop
          >
            <div className='container'>
              <img
                src={urlFor(data?.result[0].image) || mahkotaImg}
                alt={data?.result[0].title || 'mahkota'}
              />
            </div>
            <div className='container'>
              <img
                src={urlFor(data?.result[1].image) || museumImg}
                alt={data?.result[1].title || 'museum'}
              />
            </div>
            <div className='container'>
              <img
                src={urlFor(data?.result[2].image) || peristiwaImg}
                alt={data?.result[1].title || 'peristiwa'}
              />
            </div>
          </Carousel>
        </div>
      )}
    </>
  );
}
