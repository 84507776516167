import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from '../../hooks/useQueryParams';
import {
  useGetListKatagoriQuery,
  useGetListProdukQuery,
} from '../../app/serverApi';
import { urlFor } from '../../lib/client';
import './style.scss';

export const CategoryCard = (props) => {
  const { onClick, imgSrc, title, subTitle, ...rest } = props;
  return (
    <div className='category-card' {...rest}>
      <img src={imgSrc} alt='kategory' />
      <div className='detail'>
        <div className='title'>{title}</div>
        <div className='sub-title'>{subTitle}</div>
        <button onClick={onClick}>Lihat Produk</button>
      </div>
    </div>
  );
};

export const CategoryCards = () => {
  const { data: dataKategory } = useGetListKatagoriQuery();
  const navigate = useNavigate();
  return (
    <>
      {dataKategory?.result?.map((el, i) => (
        <CategoryCard
          key={i}
          imgSrc={
            el.image
              ? urlFor(el.image)
              : 'https://www.eclosio.ong/wp-content/uploads/2018/08/default.png'
          }
          title={el.title}
          subTitle={el.subTitle}
          onClick={() =>
            navigate(`/cenderamata?kategori=${el?.kategoriSlug?.current}`)
          }
        />
      ))}
    </>
  );
};

export const ItemCard = (props) => {
  const { imgSrc, title, subTitle, price, ...rest } = props;
  return (
    <div className='item-card' {...rest}>
      <img src={imgSrc} alt='kategory' />
      <div className='detail'>
        <div className='title'>{title}</div>
        <div className='short-desc'>{subTitle}</div>
        {price && <div className='price'>Rp. {price}</div>}
      </div>
    </div>
  );
};

export const ItemCards = () => {
  const queryParams = useQueryParams();
  const category = queryParams.get('kategori');
  const { data: dataProduk } = useGetListProdukQuery(category);
  const navigate = useNavigate();
  return (
    <>
      {dataProduk?.result?.map((el, i) => (
        <ItemCard
          key={i}
          imgSrc={
            el.imageCover
              ? urlFor(el.imageCover)
              : 'https://www.eclosio.ong/wp-content/uploads/2018/08/default.png'
          }
          title={el.title}
          subTitle={el.subTitle}
          price={el.price}
          onClick={() =>
            navigate(
              `/cenderamata?kategori=${category}&produk=${el.produkSlug?.current}`
            )
          }
        />
      ))}
    </>
  );
};
