export default function BookTicketSvg() {
  return (
    <svg
      fill='none'
      height='48'
      viewBox='0 0 49 48'
      width='49'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_164_1565)'>
        <g clipPath='url(#clip1_164_1565)'>
          <path
            d='M30.1421 13.6241H42.14V17.0695H30.1421V13.6241Z'
            fill='#0081AB'
          />
          <path
            d='M30.1421 22.2375H42.14V25.6829H30.1421V22.2375Z'
            fill='#0081AB'
          />
          <path
            d='M30.1421 30.8506H42.14V34.2959H30.1421V30.8506Z'
            fill='#0081AB'
          />
          <path
            d='M45.5679 5.01111H4.43235C3.52348 5.01202 2.65209 5.3753 2.00942 6.02123C1.36675 6.66715 1.0053 7.54295 1.00439 8.45643V39.4643C1.0053 40.3778 1.36675 41.2536 2.00942 41.8995C2.65209 42.5454 3.52348 42.9087 4.43235 42.9096H45.5679C46.4766 42.9082 47.3477 42.5448 47.9903 41.899C48.6329 41.2532 48.9945 40.3776 48.9958 39.4643V8.45643C48.9949 7.54295 48.6335 6.66715 47.9908 6.02123C47.3481 5.3753 46.4767 5.01202 45.5679 5.01111ZM4.43235 8.45643H23.2861V39.4643H4.43235V8.45643ZM26.7141 39.4643V8.45643H45.5679L45.5713 39.4643H26.7141Z'
            fill='#0081AB'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_164_1565'>
          <rect
            fill='white'
            height='48'
            transform='translate(0.5)'
            width='48'
          />
        </clipPath>
        <clipPath id='clip1_164_1565'>
          <rect
            fill='white'
            height='37.92'
            transform='translate(1 5)'
            width='48'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
