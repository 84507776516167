import React from 'react';

export default function Page1() {
  return (
    <>
      <div className='title-column'>
        <div className='container'>
          <div className='title'>Jadwal Operasional Museum</div>
        </div>
        <div className='jadwal'>
          <p>Selasa - Kamis 08.00 - 14.00</p>
          <p>Sabtu - Minggu 08.00 - 14.00</p>
          <span>*Senin dan Jum’at serta Hari Libur Nasional Tutup</span>
        </div>
      </div>
      <div className='separator' />
      <div className='title-column'>
        <div className='container'>
          <div className='title'>Regulasi Kunjungan Museum</div>
        </div>
        <div className='desc'>
          <p>Rencanakan hari dan waktu berkunjung Anda.</p>
          <p>Lakukan reservasi melalui website atau whatsapp museum</p>
          <p>
            Pastikan Anda dalam keadaan sehat saat dalam perjalanan ke museum.
          </p>
          <p>
            Saat membeli tiket, Anda akan diminta mengisi beberapa data pribadi
            (nama lengkap dan nomor kontak).
          </p>
          <p>
            saat anda tiba, silahkan konfirmasi reservasi anda dan melakukan
            pembayaran pada loket yang telah tersedia di museum.
          </p>
          <p>
            Jika Anda memerlukan bantuan saat berkunjung, tim keamanan dan
            asisten museum (guide) yang berada di Area museum siap membantu.
          </p>
          <p>
            Mari menjaga kesehatan bersama dan gunakan masker setiap saat dalam
            kunjungan.
          </p>
        </div>
      </div>
      <div className='separator' />
      <div className='title-column'>
        <div className='container'>
          <div className='title'>Tata Tertip Pengunjung</div>
        </div>
        <div className='grid'>
          <div className='item'>
            <img
              src='foto2 pendukung/dilarang membawa tas.png'
              alt='tata-tertib'
            />
            <figcaption>Dilarang membawa Ransel/tas</figcaption>
          </div>
          <div className='item'>
            <img
              src='foto2 pendukung/dilarang menyentuh koleksi .png'
              alt='tata-tertib'
            />
            <figcaption>Dilarang menyentuh koleksi</figcaption>
          </div>
          <div className='item'>
            <img src='foto2 pendukung/dilarang berisik.jpg' alt='tata-tertib' />
            <figcaption>Dilarang berisik</figcaption>
          </div>
          <div className='item'>
            <img src='foto2 pendukung/dilarang berlari.png' alt='tata-tertib' />
            <figcaption>Dilarang berlari</figcaption>
          </div>
          <div className='item'>
            <img
              src='foto2 pendukung/dilarang mengambil foto di gedung pusaka.png'
              alt='tata-tertib'
            />
            <figcaption>
              Dilarang mengambil foto di ruangan Gedung Pusaka
            </figcaption>
          </div>
          <div className='item'>
            <img src='foto2 pendukung/dilarang merokok.png' alt='tata-tertib' />
            <figcaption>Dilarang merokok</figcaption>
          </div>
          <div className='item'>
            <img
              src='foto2 pendukung/dilarang membuang sampah.png'
              alt='tata-tertib'
            />
            <figcaption>Dilarang membuang sampah sembarangan</figcaption>
          </div>
          <div className='item'>
            <img
              src='foto2 pendukung/dilarang membawa makanan dan minuman.png'
              alt='tata-tertib'
            />
            <figcaption>Dilarang membawa makanan dan minuman</figcaption>
          </div>
        </div>
      </div>
      <div className='separator' />
      <div className='title-column'>
        <div className='container' />
        <p>
          Saya telah membaca tata tertib dan menyetujui regulasi kunjungan
          Museum Prabu Geusan Ulun
        </p>
      </div>
    </>
  );
}
