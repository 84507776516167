export default function ShuffleSvg({ onClick }) {
  return (
    <svg
      width='58'
      height='58'
      viewBox='0 0 58 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g filter='url(#filter0_d_417_2499)'>
        <circle cx='29' cy='25' r='25' fill='#FFFBFB' />
      </g>
      <path
        d='M30.8032 19.6294L34.9706 20.8171C35.2912 20.9084 35.6097 20.6665 35.6077 20.3331L35.5809 15.9999'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M35 20C33.5479 17.9801 31.1776 17 28.5 17C24.0817 17 21 20.757 21 25.1753C21 25.4968 21.019 25.8139 21.0559 26.1255'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M27.1975 30.4961L23.0301 29.3084C22.7095 29.2171 22.391 29.459 22.3931 29.7924L22.4198 34.1256'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M23.0007 30.1255C24.4528 32.1453 26.8232 33.1255 29.5007 33.1255C33.919 33.1255 37.0007 29.3685 37.0007 24.9502C37.0007 24.6287 36.9818 24.3116 36.9449 23.9999'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <defs>
        <filter
          id='filter0_d_417_2499'
          x='0'
          y='0'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_417_2499'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_417_2499'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
