/* eslint-disable quotes */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowBack } from 'react-icons/io';
import { useHeaderShadow } from '../../hooks/useHeaderShadow';
import HomeSvg from '../../assets/svg/HomeSvg';
import { CategoryCards, ItemCards } from '../../components/cendramataCard';
import Footer from '../../components/footer';
import './style.scss';
import { useQueryParams } from '../../hooks/useQueryParams';
import PopupItem from '../../components/popupItem';
import { client } from '../../lib/client';
import { useMediaQuery } from 'react-responsive';

function index() {
  const [dataProduk, setDataProduk] = useState(null);
  const showShadow = useHeaderShadow();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: '(max-width: 37.5em)' });

  const category = queryParams.get('kategori');
  const produk = queryParams.get('produk');

  useEffect(() => {
    if (category && produk) {
      client
        .fetch(
          `*[_type == 'produk' && kategoriSlug == '${category}' && produkSlug.current == '${produk}']`
        )
        .then((result) => {
          setDataProduk(result[0]);
        });
    }
  }, [category, produk]);

  return (
    <>
      <div className={`header ${showShadow ? 'shadow' : ''}`}>
        <Link to='/'>
          <img src='/Logo Logo/logo museum(1).png' alt='logo museum' />
        </Link>
        <div className={isMobile ? '' : 'header_cendramata'}>
          Cenderamata di <span className='museum'>Museum</span>
        </div>
        {category ? (
          <IoMdArrowBack
            cursor='pointer'
            size={24}
            onClick={() => navigate('/cenderamata')}
          />
        ) : (
          <HomeSvg />
        )}
      </div>
      <div className='cendramata'>
        {category ? <ItemCards /> : <CategoryCards />}
      </div>

      <Footer cendramata donation={false} />
      {dataProduk && produk && category && (
        <PopupItem
          show={dataProduk}
          data={dataProduk}
          setShow={() => navigate(`/cenderamata?kategori=${category}`)}
        />
      )}
    </>
  );
}

export default index;
