import { useEffect, useState } from 'react';
import { dataJumlahTicket } from './data';
import './style.scss';

export default function SelectTicket({ getTicket }) {
  const newData = [...dataJumlahTicket];
  const [data, setData] = useState(newData);

  useEffect(() => {
    getTicket(data);
  }, [data]);

  return (
    <div className='select-ticket'>
      <p>Perkiraan tiket yang akan anda pesan</p>
      {data.map(({ type, price, quantity }, index) => (
        <div className='content' key={index}>
          <div className={`type ${quantity > 0 ? 'active' : ''}`}>{type}</div>
          <div className='price'>
            Rp. {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          </div>
          <div className='quantity'>
            <div
              className='operator'
              onClick={() =>
                setData((prev) => {
                  const newData = [...prev];
                  newData[index].quantity++;
                  return newData;
                })
              }
            >
              +
            </div>
            <span>{quantity}</span>
            <div
              className='operator'
              onClick={() =>
                setData((prev) => {
                  const newData = [...prev];
                  if (newData[index].quantity > 0) {
                    newData[index].quantity--;
                  }
                  return newData;
                })
              }
            >
              -
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
