import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { SiShopify } from 'react-icons/si';
import { FaFacebook } from 'react-icons/fa';
import './style.scss';

export default function Footer({ donation = true, cendramata }) {
  return (
    <div className='footer'>
      {donation && (
        <div className='donation'>
          <div className='content'>
            <div className='title'>Gabung Bersama Kami</div>
            <div className='desc'>
              kami membuka peluang bagi anda yang ingin berkerja sama dengan
              Museum Prabu Geusan Ulun berupa donasi.
            </div>
          </div>
          <a href='https://wa.me/+6287813066669' target='_blank'>
            Donasi
          </a>
        </div>
      )}

      {cendramata && (
        <div className='donation footer__cendramata'>
          <div className='content'>
            <div className='title'>
              Produk Cendramata <span>Museum</span>
            </div>
            <div className='desc'>
              Dapatkan produk cinderamata eksklusif museum dengan melakukan
              pembelian lansung di Museum Prabu Geusan Ulun atau melakukan
              pemesanan secara online
            </div>
          </div>
          <div className='link-container'>
            <a href='https://wa.me/+6287813066669' target='_blank'>
              <FaWhatsapp />
            </a>
            <a href='https://wa.me/+6287813066669' target='_blank'>
              <SiShopify />
            </a>
            <a href='https://wa.me/+6287813066669' target='_blank'>
              <FaFacebook />
            </a>
          </div>
        </div>
      )}

      <div className='footer-bottom'>
        <span>{new Date().getFullYear()}. Museum Prabu Geusan Ulun</span>
        <div className='social-media'>
          <a href='https://www.facebook.com/museum.geusan.ulun' target='_blank'>
            <AiFillFacebook color='white' />
          </a>
          <a
            href='https://www.instagram.com/prabu_geusan_ulun_museum/'
            target='_blank'
          >
            <AiOutlineInstagram color='white' />
          </a>
          <a href='https://twitter.com/MuseumPGU' target='_blank'>
            <AiOutlineTwitter color='white' />
          </a>
          <AiFillYoutube />
        </div>
      </div>
    </div>
  );
}
