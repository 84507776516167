import { useGetKegiatanQuery } from '../../app/serverApi';
import { urlFor } from '../../lib/client';
import ActivityCard from '../activityCard';
import Section from '../section';
import './style.scss';

export default function Activity() {
  const { data } = useGetKegiatanQuery();

  return (
    <div className='activity' id='activity'>
      <Section
        subTitle={[
          'Kegiatan-kegiatan yang dilakukan oleh yayasan dan pihak Museum,',
          'seperti menjadikan tempat sanggar tari, kunjungan sekolah, hingga kunjungan mahasiswa.',
        ]}
        title='Kegiatan'
      />
      <div className='activities'>
        {data?.result?.map((activity, i) => (
          <ActivityCard
            key={i}
            title={activity.title}
            desc={activity.desc}
            srcImg={urlFor(activity.image)}
          />
        ))}
      </div>
    </div>
  );
}
