export const sampleDataRadioBox = [
  {
    value: 'Selasa',
    label: 'Selasa',
  },
  {
    value: 'Rabu',
    label: 'Rabu',
  },
  {
    value: 'Kamis',
    label: 'Kamis',
  },
  {
    value: 'Sabtu',
    label: 'Sabtu',
  },
  {
    value: 'Minggu',
    label: 'Minggu',
  },
];

export const dataJumlahTicket = [
  {
    type: 'Dewasa',
    price: 5000,
    quantity: 0,
    isSelected: false,
  },
  {
    type: 'Anak',
    price: 3000,
    quantity: 0,
    isSelected: false,
  },
  {
    type: 'Asing',
    price: 20000,
    quantity: 0,
    isSelected: false,
  },
];
