export default function ArrowGame() {
  return (
    <svg
      width='24'
      height='116'
      viewBox='0 0 24 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.9393 115.061C11.5251 115.646 12.4749 115.646 13.0607 115.061L22.6066 105.515C23.1924 104.929 23.1924 103.979 22.6066 103.393C22.0208 102.808 21.0711 102.808 20.4853 103.393L12 111.879L3.51472 103.393C2.92893 102.808 1.97919 102.808 1.3934 103.393C0.807611 103.979 0.807611 104.929 1.3934 105.515L10.9393 115.061ZM10.5 6L10.5 114H13.5L13.5 6H10.5Z'
        fill='#0081AB'
      />
      <circle cx='12' cy='6' r='6' fill='#0081AB' />
    </svg>
  );
}
