/* eslint-disable no-nested-ternary */
import { useState } from 'react';

export default function Box({
  box = {
    value: `/aset 10 gambar artefak/Keris Ki Dukun.jpg`,
    type: 'image',
    identity: 'keris-ki-dukun',
  },
  setPrevValue,
}) {
  const [show, setShow] = useState(false);

  return (
    <div className='box'>
      {show &&
        (box.type === 'image' ? (
          <div className='image show'>
            <img src={box.imgSmall} alt='box' />
          </div>
        ) : (
          <div className='text'>{box.value}</div>
        ))}

      {!show && (
        <div
          className='image'
          onClick={() => {
            setShow(true);
            setPrevValue((prev) => {
              if (prev?.identity === box.identity && prev.type !== box.type) {
                setTimeout(() => {
                  setPrevValue({
                    identity: box.identity,
                    type: box.type,
                    isSame: true,
                    value: box.value[0] === '/' ? box.value : prev.value,
                  });
                }, 300);
              }
              return {
                identity: box.identity,
                isSame: false,
                value: box.value,
                type: box.type,
              };
            });
            setTimeout(() => {
              setShow(false);
            }, 1000);
          }}
        >
          <img src='/Logo Logo/logo Museum.png' alt='logo' />
        </div>
      )}
    </div>
  );
}
