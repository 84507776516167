import { useEffect, useState } from 'react';
import ArrowGame from '../../assets/svg/ArrowGame';
import ArrowLeft2 from '../../assets/svg/ArrowLeft2';
import ArrowRight2 from '../../assets/svg/ArrowRight2';
import ShuffleSvg from '../../assets/svg/ShuffleSvg';
import Box from './Box';
import { dataBoxes } from './dataBoxes';
import './style.scss';

export default function GameMobile() {
  const [show, setShow] = useState('home');
  const [boxes, setBoxes] = useState(dataBoxes);
  const [prevValue, setPrevValue] = useState(null);

  function shuffleArray(array) {
    const newArray = [...array];
    const temp = newArray.flat();
    for (let i = temp.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [temp[i], temp[j]] = [temp[j], temp[i]];
    }
    let newBoxes = [
      [temp[0], temp[1], temp[2]],
      [temp[3], temp[4], temp[5]],
      [temp[6], temp[7], temp[8]],
    ];
    setBoxes(newBoxes);
    return newBoxes;
  }

  useEffect(() => {
    if (show === 'home') {
      setTimeout(() => {
        setShow('info');
      }, 1500);
    }

    dataBoxes.flat().forEach((picture) => {
      const img = new Image();
      if (picture.imgSmall) {
        img.src = picture.imgSmall;
      }
    });

    shuffleArray(boxes);
  }, []);

  return (
    <div className='game-mobile'>
      {show === 'home' && (
        <div className='home'>
          <img src='/Logo Logo/logo museum(1).png' alt='logo' />
          <img src='/foto2 pendukung/Hiasan 2.png' alt='hiasan' />
        </div>
      )}
      {show === 'info' && (
        <div className='info'>
          <div className='top' />
          <div className='content'>
            <div className='title'>
              Jelajahi koleksi karya di museum melalui Mini Game!
            </div>
            <p>
              Mari berkenalan dengan koleksi museum melalui mini games sebagai
              sarana edukasi menarik yang dikemas menggunakan teknologi AR dan
              audio visual dalam memperkenalkan koleksi, dengan berburu harta
              karun berupa marker yang akan menampilkan AR koleksi.
            </p>
            <p>
              Ayo scan marker yang ada di museum jika kamu ingin mulai melihat
              benda cagar budaya dengan pengalaman yang berbeda!
            </p>
            <ArrowRight2 onClick={() => setShow('let-play')} />
          </div>
        </div>
      )}
      {show === 'let-play' && (
        <div className='let-play'>
          <div className='title'>Match Pair Game!</div>
          <button onClick={() => setShow('mulai')}>MULAI</button>
          <button onClick={() => setShow('cara-bermain')}>CARA BERMAIN</button>
        </div>
      )}
      {show === 'cara-bermain' && (
        <div className='cara-bermain info'>
          <div className='top' />
          <div className='content'>
            <div className='title'>Cara Bermain !</div>
            <p>
              Mari berkenalan dengan koleksi museum melalui mini games sebagai
              sarana edukasi menarik yang dikemas menggunakan teknologi AR dan
              audio visual dalam memperkenalkan koleksi, dengan berburu harta
              karun berupa marker yang akan menampilkan AR koleksi.
            </p>
            <ArrowGame />
            <img src='/foto2 pendukung/step1.png' alt='step1' />
            <img src='/foto2 pendukung/step2.png' alt='step2' />
            <img src='/foto2 pendukung/step3.png' alt='step3' />
            <img src='/foto2 pendukung/step4.png' alt='step4' />
            <img src='/foto2 pendukung/step5.png' alt='step5' />
            <button onClick={() => setShow('mulai')}>MULAI</button>
          </div>
          <div className='footer'>
            <div className='logos'>
              <img src='Logo Logo/logo museum(1).png' alt='logo museum' />
              <img src='Logo Logo/logo telkom.png' alt='logo telkom' />
              <img src='Logo Logo/logo innovilage.png' alt='logo museum' />
            </div>
            <div className='bottom'>
              <p>2022. Museum Prabu Geusan Ulun</p>
              <p>by Innovillage 2022</p>
              <p>Telkom University</p>
            </div>
          </div>
        </div>
      )}
      {show === 'mulai' && !prevValue?.isSame && (
        <div className='mulai'>
          <div className='top'>Match Pair Game!</div>
          <div className='content'>
            <p>Buka setiap kartu</p>
            <p>Lalu samakan kartu berdasarkan gambar dan nama bendanya!</p>
            <div className='boxes'>
              {boxes.map((rowBox, indexRB) => (
                <div className='row-box' key={indexRB}>
                  {rowBox.map((box, indexBox) => (
                    <Box key={indexBox} setPrevValue={setPrevValue} box={box} />
                  ))}
                </div>
              ))}
            </div>
            <div className='bottom'>
              <ShuffleSvg onClick={() => shuffleArray(boxes)} />
              <ArrowLeft2 onClick={() => setShow('let-play')} />
            </div>
          </div>
        </div>
      )}
      {prevValue?.isSame && (
        <div className='keris mulai'>
          <div className='top'>Match Pair Game!</div>
          <div className='content'>
            <div className='title'>{prevValue.identity}</div>
            <div className='image'>
              <img src={prevValue.value} alt='keris' />
            </div>
            <div className='scan'>
              <p className='mt'>Scan Marker yang telah disediakan Museum</p>
              <p>untuk melihat katalog dengan pengalaman yang berbeda</p>
            </div>
            <ArrowLeft2 onClick={() => setPrevValue(null)} />
          </div>
        </div>
      )}
    </div>
  );
}
