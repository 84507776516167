import { useNavigate } from 'react-router-dom';
import kerisImg from '../../assets/images keris/Pedang Badik Curug Aul.jpg';
import './style.scss';

export default function Card({
  image = kerisImg,
  altImg = 'keris',
  title = 'Pedang Senjata',
  number = 41,
  noInventaris = '041/A/i/MPGU/f/V/2018',
  isHome = false,
  setSelectShow,
  setShowPopup,
  index,
}) {
  const navigate = useNavigate();

  return (
    <div
      className='card'
      onClick={() => {
        if (!isHome) {
          setShowPopup(true);
          setSelectShow(index);
        } else {
          navigate('/koleksi');
        }
      }}
    >
      <div className='desc'>
        <div className='title'>{title}</div>
        <div className='number'>{number}</div>
        <div className='inventaris'>
          <div className='name'>no inventaris</div>
          <div className='number'>{noInventaris} </div>
        </div>
      </div>
      <div className='image'>
        <img alt={altImg} src={image} />
      </div>
    </div>
  );
}
