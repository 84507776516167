import { useLocation, useNavigate } from 'react-router-dom';

export default function ListMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <li
        className={location.pathname === '/' ? 'active' : ''}
        onClick={() => navigate('/')}
      >
        Beranda
      </li>
      <li
        className={location.pathname === '/sejarah' ? 'active' : ''}
        onClick={() => navigate('/sejarah')}
      >
        Sejarah
      </li>
      <li
        className={location.pathname === '/koleksi' ? 'active' : ''}
        onClick={() => navigate('/koleksi')}
      >
        Koleksi
      </li>
      <li
        className={location.pathname === '/reservasi' ? 'active' : ''}
        onClick={() => navigate('/reservasi')}
      >
        Reservasi
      </li>
      <li
        className={location.pathname === '/cenderamata' ? 'active' : ''}
        onClick={() => navigate('/cenderamata')}
      >
        Cenderamata
      </li>
    </>
  );
}
