import MapSvg from '../../assets/svg/MapSvg';
import MessageSvg from '../../assets/svg/MessageSvg';
import CardInTouch from '../cardTouch';
import Section from '../section';
import './style.scss';

export default function GetInTouch() {
  return (
    <div className='get-in-touch'>
      <Section
        title='Kontak Kami'
        subTitle={[
          'Jika ingin lebih tahu lebih lanjut mengenai Museum Prabu geusan Ulun,',
          'langsung saja hubungi atau datangi kami lewat info di bawah sini.',
        ]}
      />
      <div className='cards'>
        <CardInTouch />
        <CardInTouch
          type='center'
          Svg={<MapSvg />}
          line1='Jalan Prabu Geusan Ulun no. 40'
          line2='Regol Wetan, Sumedang Selatan'
          line3='Sumedang, Jawa Barat'
          textButton='Google maps'
          href='https://maps.app.goo.gl/5WJJGLnNp69UfETX9'
        />
        <CardInTouch
          Svg={<MessageSvg />}
          line1='geusan.ulun.museum@gmail.com'
          line2='museum.geusan.ulun'
          line3='Contact us'
          textButton='Sending Email'
          href='mailto: http://geusan.ulun.museum@gmail.com'
        />
      </div>
    </div>
  );
}
