import keris1 from '../../assets/foto2 pendukung/keris panunggul Naga.jpg';
import keris2 from '../../assets/foto2 pendukung/keris naga sasra 2.jpg';
import './style.scss';

export default function KerisHistory() {
  return (
    <div className='keris-history'>
      <div className='container'>
        <img src={keris1} alt='keris' />
        <figcaption>Keris Panunggul Naga</figcaption>
        <figcaption>No.Reg. GU 00003X No.Inv. 05.1632</figcaption>
        <div className='desc'>
          Keris panunggul Naga merupakan keris satu-satunya Prabu Geusan Ulun
          (Raja Sumedang Larang, 1578-1610 yang turun temurun jatuh kepada para
          bupati keluarga di Kabupaten Sumedang. Prabu Geusan Ulun
          memproklamirkan dirinya sebagai nalendra penerus kerajaan Sunda dan
          mewarisi daerah bekas wilayah Pajajaran, sebagaimana dikemukakan dalam
          Pustaka Kertabhumi I/2 (h. 69) yang berbunyi; “Ghesan Ulun
          nyakrawartti mandala ning Pajajaran kangwus pralaya, ya ta sirna, ing
          bhumi Parahyangan. Ikang kedatwan ratu Sumedang haneng Kutamaya ri
          Sumedangmandala” (Geusan Ulun memerintah wilayah Pajajaran yang telah
          runtuh, yaitu sirna, di bumi Parahiyangan. Keraton raja Sumedang ini
          terletak di Kutamaya dalam daerah Sumedang)
        </div>
      </div>
      <div className='container'>
        <img src={keris2} alt='keris' />
        <figcaption>Keris Panunggul Naga</figcaption>
        <figcaption>No.Reg. GU 00003X No.Inv. 05.1632</figcaption>
        <div className='desc'>
          Keris Naga Sasra II merupakan keris peninggalan dari Pangeran
          Soerianagara Koesoemahdinata IX (Pangeran Kornel) Bupati Sumedang yang
          memerintah pada tahun 1791-1828. Pangeran Soerianagara Koesoemahdinata
          IX adalah Bupati satusatunya yang memerintah dalam empat periode
          pemerintahan yakni pada masa pemerintahan Belanda, Inggris, Perancis
          dan Compagnie (kompeni). Keris ini merupakan saksi bisu peristiwa
          Cadas Pangeran di Sumedang.
        </div>
      </div>
    </div>
  );
}
