export default function MessageSvg() {
  return (
    <svg
      fill='none'
      height='72'
      viewBox='0 0 72 72'
      width='72'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_164_1760)'>
        <path
          d='M69.8484 2.16174C69.4592 1.75878 68.9674 1.47979 68.4308 1.35757C67.8943 1.23534 67.3354 1.27496 66.8198 1.47178L3.3615 25.4705C2.81422 25.6864 2.34305 26.0704 2.01056 26.5714C1.67807 27.0723 1.5 27.6667 1.5 28.2754C1.5 28.8841 1.67807 29.4785 2.01056 29.9794C2.34305 30.4804 2.81422 30.8644 3.3615 31.0803L31.0524 42.5997L42.1287 71.3982C42.3368 71.9423 42.6935 72.4113 43.1549 72.7471C43.6162 73.0829 44.1619 73.271 44.7248 73.2881C45.3077 73.2757 45.8733 73.0798 46.347 72.7264C46.8207 72.3729 47.1804 71.8785 47.3785 71.3082L70.4542 5.31158C70.6507 4.78084 70.6981 4.20293 70.5909 3.64524C70.4836 3.08755 70.2261 2.57305 69.8484 2.16174ZM44.7248 61.8887L36.6771 40.8898L50.4937 26.5205L46.4266 22.2907L32.4946 36.78L12.4187 28.2904L62.9834 9.30138L44.7248 61.8887Z'
          fill='#0081AB'
        />
      </g>
      <defs>
        <clipPath id='clip0_164_1760'>
          <rect fill='white' height='72' width='72' />
        </clipPath>
      </defs>
    </svg>
  );
}
