import ContentHistory from '../contentHistory';
import Section from '../section';

import './style.scss';

export default function History() {
  return (
    <div className='history' id='history'>
      <Section
        title='Sejarah'
        subTitle={[
          'Mari simak mengenai sejarah museum Prabu Geusan Ulun.',
          'Perubahan nama dari Selayang Pandang Museum menjadi Prabu Geusan Ulun.',
        ]}
      />
      <ContentHistory showSeeMore />
    </div>
  );
}
