export default function ArrowRight2({ onClick }) {
  return (
    <svg
      width='58'
      height='58'
      viewBox='0 0 58 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <g filter='url(#filter0_d_438_4208)'>
        <circle cx='29' cy='25' r='25' fill='#FFFBFB' />
      </g>
      <path
        d='M25 18L32.57 24.6237C32.7976 24.8229 32.7976 25.1771 32.57 25.3763L25 32'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <defs>
        <filter
          id='filter0_d_438_4208'
          x='0'
          y='0'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_438_4208'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_438_4208'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
