import museumImg from '../../assets/foto2 pendukung/museum tampak depan.jpg';
import './style.scss';

export default function ActivityCard({
  title = 'Tempat Sanggar Tari',
  desc = 'Newton thought that light was made up of particles, but then it was discovered',
  srcImg = museumImg,
}) {
  return (
    <div className='activity-card'>
      <div className='content'>
        <div className='title'>{title}</div>
        <div className='desc'>{desc}</div>
      </div>
      <img src={srcImg} alt='' />
    </div>
  );
}
