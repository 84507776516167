import { useEffect, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import './style.scss';

export default function MoveToTop() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const onScroll = () => {
      const screenY = window.scrollY;
      // Header bar
      if (screenY > 10) {
        setShow(true);
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div
      className='move-to-top'
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    >
      <AiOutlineArrowUp />
    </div>
  );
}
