import SeeMore from '../seeMore';
import penyerahanImg from '../../assets/foto2 pendukung/penyerahan pusaka pejajaran.jpg';
import './style.scss';
import { useNavigate } from 'react-router-dom';

export default function ContentHistory({
  showSeeMore = false,
  title = 'Penyerahan Pusaka Pajajaran',
  desc = '  Museum Prabu Geusan Ulun resmi dibuka untuk umum pada tanggal 24 Januari 1985. Museum pada saat ini menempati 6 gedung terdiri dari Gedung Srimanganti, Bumi Kaler, Genden, Gedung Pusaka, Gedung Gamelan,dan Gedung Kareta.',
  reverse = false,
  imgCaptions = null,
  imgSrc = penyerahanImg,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`content-history ${reverse ? 'reverse' : ''} ${
        showSeeMore ? 'cursor-pointer' : ''
      }`}
    >
      <div className='image'>
        <img
          src={imgSrc}
          alt='penyerahan'
          onClick={() => showSeeMore && navigate('sejarah')}
        />
        {imgCaptions?.map((caption, i) => (
          <figcaption key={i}>{caption}</figcaption>
        ))}
      </div>
      <div className='content'>
        <div
          className='title'
          onClick={() => showSeeMore && navigate('sejarah')}
        >
          {title}
        </div>
        <div className='desc'>{desc}</div>
        {showSeeMore && <SeeMore />}
      </div>
    </div>
  );
}
