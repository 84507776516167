export default function ArrowLeft({ onClick }) {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.9997 3.33331C10.7949 3.33331 3.33301 10.7952 3.33301 20C3.33301 29.2047 10.7949 36.6666 19.9997 36.6666C29.2044 36.6666 36.6663 29.2047 36.6663 20C36.6663 10.7952 29.2044 3.33331 19.9997 3.33331ZM22.8645 12.7477C23.1879 12.4889 23.6599 12.5413 23.9187 12.8648C24.1774 13.1882 24.125 13.6602 23.8015 13.919L16.2003 20L23.8015 26.081C24.125 26.3397 24.1774 26.8117 23.9187 27.1352C23.6599 27.4586 23.1879 27.5111 22.8645 27.2523L15.0192 20.9761C14.3937 20.4757 14.3937 19.5243 15.0192 19.0239L22.8645 12.7477Z'
        fill='black'
      />
    </svg>
  );
}
