export default function Page3({ data }) {
  console.log(data);
  return (
    <div className='title-column page-3'>
      <div className='container'>
        <div className='title'>Ringkasan Pemesanan</div>
      </div>
      <div className='rangkuman'>
        <h2>Berikut rangkuman pemesanan tiket anda :</h2>
        <div className='content'>
          <div className='row'>
            <div className='column-name'>Atas Nama</div>
            <div className='column-desc'>: {data?.pemesan}</div>
          </div>
          <div className='row'>
            <div className='column-name'>Hari Kunjungan</div>
            <div className='column-desc'>: {data?.selectDay}</div>
          </div>
          <div className='row'>
            <div className='column-name'>Jam Kunjungan</div>
            <div className='column-desc'>: {data?.selectTime} WIB</div>
          </div>
          <div className='row'>
            <div className='column-name'>Jumlah Tiket</div>
            <div className='column-desc jumlah-tiket'>
              <span>:</span>
              <div className='column'>
                {data?.selectTicket?.map((e, i) => (
                  <div className='row' key={i}>
                    <span
                      className={data?.selectTicket?.length > 1 ? '' : 'none'}
                    >
                      -
                    </span>
                    <span>{e.type}</span>
                    <span>
                      Rp.{' '}
                      {e.price
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </span>
                    <span>X{e.quantity}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='column-name'>Total</div>
            <div className='column-desc'>
              : Rp.{' '}
              {data?.total?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </div>
          </div>
        </div>
        <div className='separator' />
      </div>
    </div>
  );
}
