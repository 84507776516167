export const dataCatalog = [
  {
    inventaris: '041/A/i/MPGU/f/V/2018',
    title: 'Pedang Tjikoreh',
    desc: 'Pedang berwarangka. dua sisi bermotif geometris dan sulur. pada salah satu sisi bilah bagian dasar, tertera deskripsi "TJIKEROEH 1901"tampah jejak pemakaian pada sisi tajam bilah pedang. gagang pedang terbuat dari gading dihias kuningan. pada ujung pangkal gagang, terdapat bulatan seperti knob dengan ukiran penuh. dekat dasar gagang terdapat pelindung tangan berupa ring kuningan yang berbentuk elips dan kerang. warangka pedang terbuat dari kayu dilapisi logam kuningan ang sedikit aus.',
    history:
      'Pedang Tjikeroeh, bilah Tjikeroeh sangat khas dan beraneka ragam karena dihias motif seperti tanaman yang disebut simbol pajimatan Wawanen. hulunya juga khas, bentuk bulat lonjong, ditutupdengan lgam bahan kuningan, dihiasi dengan tiga simbol binatang "kica-kica". Hand Guard (pelindung tangan) bentuk suku "mencek"dan bentuk kulit kerang, terbuat dari kuningan. pada zaman dahulu, senjata Tjikeroeh biasad ipakai oleh kaum Aom/bangsawan Sumedang',
    img: '/aset 10 gambar artefak/Pedang Tjikeroeh.png',
    number: 41,
  },
  {
    inventaris: '004/A/i/MPGU/f/IX/2018',
    title: 'Pedang Ki Mastak',
    desc: 'Pedang panjang dan berat. bilah dari logam dengan bentuk seperti pisau, tajam disatu sisi dengan sisi lain tumpul dan lebih tebal. hiasan dasar bilah juga terbuat dari logam yang ditempa dan dibentuk untuk menerupai kerang. dasar dilapisi oleh logam kuningan motif flora. bentuk batang silang melengkung kearah gagang. gagang terbuat dari kayu berat dilapisi logam dengan hiasan geometris. bentuk gagang menyerupai kepala hewan.hiasan ujung gagang tampah bulatan sampingan kiri/kanan seperti bulatan mata. warangka terbuat dari kayu lapis logam berupa kuningan dihiasi motif suluran.',
    history:
      'Pedang Ki Mastak** persenjataan yang digunakan dalam perang pada abad ke 16 pada umumnya berupa senjata dari logam, baik tombak maupun pedang',
    img: '/aset 10 gambar artefak/Pedang Ki Mastak.jpg',
    number: 4,
  },
  {
    inventaris: '003/A/i/MPGU/f/IX/2018',
    title: 'Keris Ki Dukun',
    desc: 'Dapur keris luk 5 lengkap dengan greneng, ganja, kinatah dan sogokan depan belakang. ring tangkal (selut) bentuk bulat dihias batu semacam kristal dan logam kuningan yang telah aus. ring dasar bilah (mendhak) warna kuning keemasan. gagang terbuat dari kayu dilapisi logam motif sulur. bagian lengkap terdapat batuk (kepala), sirah belakang, gigir, ukiran, wetengan, dan bungku;. warangka terbuat daru bambu pada bagiang dalam warangka. bagian wadah bagian dekat gagang berlapis kayu. seluruh warangka dilapisi logam kuningan. hiasan warangka bagian atas berupa motif suluran, dan kaligrafi bagian tengah. bagian badan dihiasi kaligrafi. tampah keris ini berfungsi sebagai jimat atau alat pengobatan sebagaimana tampak dari rajah ang menghias warangka. selain itu, nama keris Ki Dukuh Mengindikasikan fungsinya sebagai pengobatan',
    history:
      'Keris Ki Dukun** berdasarkan buku sejarah leluhur sumedang, keris ini ditemukan oleh Prabu Gadjah Agung, putra Tadji Malela Raja Sumedang Larang yang berkuasa sekitar tahun 900an atau abad ke 10 masehi, tetapi hiasan kaligrafi pada warangka merupakan keris tersebut pengaruh islam. jika mengacu pada babad banten dan cirebon, islam baru ada di tatar sunda sekitar abad ke 12 - 13 M',
    img: '/aset 10 gambar artefak/Keris Ki Dukun.jpg',
    number: 3,
  },
  {
    inventaris: '001/A/i/MPGU/f/IX/2018',
    title: 'Keris Panunggul Naga',
    desc: 'Keris ini salah satu benda regalia Sumedang. dapur lengkap keris berlekuk tujuh dangan ring rusak / patah motif sulur pada ring dengan greneng, ganja, kinatah dan sogokan depan belanag. ring tangkai (selut) berbentuk bulat dihiasi batu semacam kristal dan logam yang telah aus dan kendur. ring dasar bilah (mendhak) tertutup selut. gagang terbuat dari kayu yang dilapisi logam berupa kuningan dan dihiasi permata seperti kombinasi intan dan kristal. bagian lengkap terdapat bantuk (kepala), sirah belakang, gigir, ukiran, wetengan dan bungkul. gagang/dedernya bentuk burung mengikuti gaya yogyakarta terdapat patra di tengah. warangka terbuat dari kayu berlapis logam dengan hiasan berlian campuran mirah dan batuan kristal lainnya. hiasan warangka selain dari logam juga kombinasi dengan kulit hewan pada bagian mulut warangka. bentuk lambang motif geometris serupa flora. pada permukaan bawah (sisi reverse) warangka terdapat inskripsi bertulisan huruf arab bentuk kaligrafi',
    history:
      'Keris Panunggul Naga** keris ini milik Prabu Geusan Ulun (1580-1608) merupakan raja kerajaan Sumedang Larang yang menjadi urutan keturunan penguasa Sumedang.akan tetapi melihat patranya, keris tersebut diperoleh pengaruh dari mataram islam. mataran islah dibawah pemrintahan Sutawijaya/Panembahan Senopati (1586-1601) menaklukhkan Galuh (salah satu kerajaan ditahan sunda) tahun 1595. sumedang menyerahkan diri pada mataran islam tahun 1620',
    img: '/aset 10 gambar artefak/keris panunggul naga.png',
    number: 1,
  },
  {
    inventaris: '021/A/i/MPGU/f/IX/2018',
    title: 'Pedang Duhul',
    desc: 'Pedang dengan bilah dari logam yang dibuat dengan teknologi tempa seperti pembuatan keris, akni terdapat sogokan pada pangkal bilah. bilah terdapat kerusakan berupa korosi pada bagian mata bilah (yang tajam) pada bagian pangkal terdapat korosi dan relief geometris (garis lurus). bentuk deder bentuk singa dengan bagian pegangan berbalut kuningan. ujung deder terdapat motif suluran dengan motif matahari pada bagian tengahnya. pada bagian warangka terdapat hiasan suluran terbuat dari kuningan. pada ujung bilah terdapat hiasan berbentuk menyerupai daun (vertikal), hewan (horizontal). kondisi warangka terawat. warangka memiliki panjang 36 cm dengan lebar 8,2cm dengan tebal 1,6 cm',
    history: '',
    img: '/aset 10 gambar artefak/pedang Duhul.jpg',
    number: 21,
  },
  {
    inventaris: '020/A/i/MPGU/f/IX/2018',
    title: 'Pedang Badik Curug Aul',
    desc: 'terdapat guratan pada bilah. gagang bilah berbentuk singa pada bagian pangkalnya yang terbuat dari bahan kayu. pegangan gagang memiliki motif hias geometris. pada bagian pangkal bilah terdapat hiasan suluran yang menonjol. begitu pula pada bagian tempat masuk bilah terdapat hiasan suluran. bilah berbahan besi dengen teknologi tempa pada pembuatannya. terdapat kerusakan pada bilah berupa korosi pada bagian sisi yang tajam. kondisi warangka bilah terbelah yang memiliki hiasan suluran pada ujung warangka. ukuran warangka dengan panjang 37,5 cm dan lebar 9,5 cm',
    history: '',
    img: '/aset 10 gambar artefak/Pedang Badik Curug Aul.jpg',
    number: 20,
  },
  {
    inventaris: '026/A/i/MPGU/f/IX/2018',
    title: 'Kujang Wayang',
    desc: 'kujang merupakan senjata yang berfungsi sebagai benda spiritual atau lebih dikenal dengan istilah pusaka dan jimat (azimat) dengan ujung bilah bentuk kokoh wayang. bilah pamor debuat dengan teknologi tempa. berdasarkan keterangan pengelola, wayang pada ujung bilah dinamakan srikandi dilambangkan sebagai kesuburan. biasanya dipakai saat upacara pertanian atau kehamilan',
    history:
      'kujang berasal dari kata Ku Jawa Hyang atau Ku Dyah Hyang dengan tahapan antara lain kujang berasal dari kata Kudi Hyang atau Kudi Dyah Hyang yang merujuk kepada Ku Dayang Sumbi. pernyataan tersebut sebagai wujud dari dimulainya sistem ketatanegaraan di wilayah Nusa Kendeng atau Dwipantara (Nama pulai jawa saat itu)',
    img: '/aset 10 gambar artefak/keris wayang (2).png',
    number: 26,
  },
  {
    inventaris: '005/A/i/MPGU/f/IX/2018',
    title: 'Keris Naga Sasra',
    desc: 'Dapur keris berbentuk 11 dengan bilah motif lengkap dengan greneng, ganja, kinatah, dan sogokan depan belakang. ring tangkai (selut) sudah hilang, ring dasar bilah (mendhak) warna kuning keemasan. gagang terbuat dari kayu dengan bagian batuk (kepala), sirah belakang, gigir, patra, ukiran, wetengan, dan bungkul. dasar bilah berhias motif naga, ceplok bunga/kelopak enam setengah dan dasar bilah dengan hiasan. warangka terbuat dari bambu dan kayu lapis logam kuningan. bagian atas warangka terdapat gambar makhluk halus pada satu sisi yang bagian bawah penuh dengan motif sulur. ring kemasan 12 ceruk dengan bulatan-bulatan kecil',
    history: 'Keris Nagasasra milik penembahan Rangga Gempol III',
    img: '/aset 10 gambar artefak/keris nagasastra.jpg',
    number: 5,
  },
  {
    inventaris: '027/A/i/MPGU/f/IX/2018',
    title: 'Keris Pajajaran',
    desc: 'Ring tangkal (selut) bentuk bulat dihias batu semacam kristal dan logam kuningan yang telah aus. ring dasar bilah (mendhak) warna kuning keemasan. gagang terbuat dari kayu dilapisi logam motif sulur. bagian lengkap terdapat batuk (kepala), sirah belakang, gigir, ukiran, wetengan, dan bungku;. warangka terbuat daru bambu pada bagiang dalam warangka. bagian wadah bagian dekat gagang berlapis kayu. seluruh warangka dilapisi logam kuningan. hiasan warangka bagian atas berupa motif suluran, dan kaligrafi bagian tengah. bagian badan dihiasi kaligrafi. tampah keris ini berfungsi sebagai jimat atau alat pengobatan sebagaimana tampak dari rajah ang menghias warangka. selain itu, nama keris Ki Dukuh Mengindikasikan fungsinya sebagai pengobatan',
    history:
      'Keris Ki Dukun** berdasarkan buku sejarah leluhur sumedang, keris ini ditemukan oleh Prabu Gadjah Agung, putra Tadji Malela Raja Sumedang Larang yang berkuasa sekitar tahun 900an atau abad ke 10 masehi, tetapi hiasan kaligrafi pada warangka merupakan keris tersebut pengaruh islam. jika mengacu pada babad banten dan cirebon, islam baru ada di tatar sunda sekitar abad ke 12 - 13 M',
    img: '/aset 10 gambar artefak/kujang kudi.jpg',
    number: 27,
  },
  {
    inventaris: '006/A/i/MPGU/f/IX/2018',
    title: 'Keris Naga Sasra I',
    desc: 'Keris bentuk enam dengan bilah raya motif. dasar bilah bermotif naga dengan ekor yang menjulur ketengahbilah, kombinasi motif flora dan dihiasi sisik naga. dasar bilah berbentuk ring keemasan berhiaskan bulatan. Warangka terbuat dari kayu dan bambu dilapis logam kuningan.bagian atas warangka terdapat gambar makhluk pada satu sisi yang bagian bawahnya penuh dengan motif sulur.',
    history:
      'Keris Naga Sastra I dimiliki oleh pangeran Kornel, Bupati Sumedang atau Pangeran Kusumahdinata (1792 - 1828).dalam sejarah leluhur sumedang, pada saat pembangunan jalan pos menyambut Daendels  dengan uluran tangan kiri, tangan kanan memegang erat keris “Naga Sasta”. dikarenakan bertekat untuk menghentikan pekerjaan berat yang dibebankan kepada rakyatnya aibat pembangunan ',
    img: '/aset 10 gambar artefak/keris naga sasra II.png',
    number: 6,
  },
];
