import Card from '../card';
import Section from '../section';
import keris2 from '.././../assets/images keris/Keris Ki Dukun.jpg';
import './style.scss';
import SeeMore from '../seeMore';
import { useGetKatalogQuery } from '../../app/serverApi';
import { urlFor } from '../../lib/client';

export default function Catalog() {
  const { data, isLoading } = useGetKatalogQuery();

  if (isLoading) {
    return null;
  }

  return (
    <div className='catalog' id='catalog'>
      <Section
        subTitle={[
          'Mari melihat-lihat koleksi Museum Prabu Geusan Ulun,',
          'yang meliputi 6 gedung dengan benda-benda bersejarah yang berbeda-beda.',
        ]}
        title='Koleksi'
      />
      <div className='cards'>
        {data?.result?.slice(0, 2).map((e, i) => (
          <Card
            key={i}
            isHome
            alt={e.title}
            image={urlFor(e.image)}
            noInventaris={e.no_inventaris}
            number={e.number}
            title={e.title}
          />
        ))}
      </div>
      <SeeMore to='/koleksi' />
    </div>
  );
}
