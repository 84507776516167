import React from 'react';
import PhoneSvg from '../../assets/svg/PhoneSvg';
import './style.scss';

export default function CardInTouch({
  line1 = '(+62) 878 1306 6669',
  line2 = '(0261) 201714',
  line3 = 'Jam Kerja: Selasa-Kamis, 08:00-14:00',
  textButton = 'Whatsapp',
  type = '',
  Svg = <PhoneSvg />,
  href = 'https://wa.me/+6287813066669',
}) {
  return (
    <div className={`card-touch ${type === 'center' ? 'typeCenter' : ''}`}>
      {Svg}
      <h5>{line1}</h5>
      <h5>{line2}</h5>
      <span>{line3}</span>
      <a href={href} target='_blank'>
        {textButton}
      </a>
    </div>
  );
}
