import { Link } from 'react-router-dom';
import ArrowSvg from '../../assets/svg/ArrowSvg';

import './style.scss';

export default function SeeMore({
  text = 'Klik disini untuk info lebih lanjut!',
  to = '/sejarah',
  onClick,
}) {
  return (
    <Link className='see-more' onClick={onClick} to={to}>
      <span>{text}</span>
      <ArrowSvg />
    </Link>
  );
}
