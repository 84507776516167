import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer';
import Header from '../../components/header';
import HeaderBack from '../../components/header/HeaderBack';
import MoveToTop from '../../components/moveToTop';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import './style.scss';
import { linkTextWa } from './textWa';

export default function Ticketing() {
  const [page, setPage] = useState(1);
  const [selectDay, setSelectDay] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [selectTicket, setSelectTicket] = useState(null);
  const [pemesan, setPemesan] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const selectedTicket = selectTicket?.filter((e) => e.quantity > 0);

  const total = selectedTicket?.reduce((prev, { quantity, price }) => {
    return prev + quantity * price;
  }, 0);

  const isComplete =
    pemesan && selectDay && selectedTicket.length > 0 && selectTime;

  const handleBack = () => {
    if (page === 1) {
      navigate(-1);
    } else if (page === 2) {
      setPage(1);
    } else if (page === 3) {
      setPage(2);
    }
  };

  return (
    <>
      <Header />
      <div className='ticketing'>
        {[2, 3].includes(page) && (
          <span onClick={handleBack} className='kembali'>
            Kembali
          </span>
        )}
        {page === 1 && <Page1 />}
        {page === 2 && (
          <Page2
            setSelectDay={setSelectDay}
            setSelectTime={setSelectTime}
            setSelectTicket={setSelectTicket}
            setPemesan={setPemesan}
            pemesan={pemesan}
            currentTime={selectTime}
            currentDay={selectDay}
          />
        )}
        {page === 3 && (
          <Page3
            data={{
              pemesan,
              selectDay,
              selectTicket: selectedTicket,
              selectTime,
              total,
            }}
          />
        )}
        {page !== 3 && (
          <button
            onClick={() => setPage((prev) => prev + 1)}
            className={!isComplete && page === 2 ? 'disable' : ''}
            disabled={page === 2 && !isComplete}
          >
            LANJUTKAN
          </button>
        )}
        {page === 3 && (
          <a
            href={`https://api.whatsapp.com/send/?phone=%2B6287813066669&text=${linkTextWa(
              {
                pemesan,
                selectDay,
                selectTime,
                selectedTicket,
                total,
              }
            )}`}
            target='_blank'
            className={!isComplete && page === 2 ? 'disable' : ''}
            disabled={page === 2 && !isComplete}
          >
            KIRIM
          </a>
        )}
      </div>
      <Footer donation={page === 3} />
      <MoveToTop />
    </>
  );
}
