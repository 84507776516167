import './style.scss';

export default function Section({ title = 'All about the museum', subTitle }) {
  return (
    <div className='section'>
      <h2 className='title'>{title}</h2>
      {subTitle?.map((text, i) => (
        <h6 className='sub-title' key={i}>
          {text}
        </h6>
      ))}
    </div>
  );
}
